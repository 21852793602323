import * as Fathom from "fathom-client"
import { type Instance, cast, flow, getSnapshot, types } from "mobx-state-tree"

import { AccessTokenModel, type PipelineModel, SpaceModel, api } from "#api"
import { appId, appStore, isProduction } from "#app"
import { metricsApi } from "#metrics"
import { router } from "#router"
import { PipelineCreateType } from "../../enums"

export const templates = {
	echo: `import json

def handler(data, log):
    log.info("Echo: " + json.dumps(data))

    return data
`,
	aiAnomalyDetection: `import openai
import json

def handler(data, log):
    """
    Generate AI insights, detect anomalies and transform server logs data.
    """
    log.info("Event: " + json.dumps(data), data=data)

    insights = detect_anomalies(data)
    return json.loads(insights)


def detect_anomalies(log):
    # Generate insights using OpenAI's chat completion endpoint
    response = openai.chat.completions.create(
        model="gpt-3.5-turbo",
        response_format={"type": "json_object"},
        messages=[
            {
                "role": "system",
                "content": "You are a great data analyst to detect anomalies on server logs.",
            },
            {
                "role": "user",
                "content": f"Analyze the following log: {log}, identify if it is normal, unusual or suspicious and return only normal, unusual or suspicious in the JSON with status attribute and log itself as a second variable",
            },
        ],
        max_tokens=100,
        temperature=0.5,
    )

    insights = response.choices[0].message.content
    return insights
`,
	removeNull: `import json

def handler(data, log):
    cleaned_data = {k: v for k, v in data.items() if v is not None}
    return cleaned_data
`,
	pii: `import re
import json

def handler(data, log):
    # Define regex patterns for PII detection
    patterns = {
        "name": r"[A-Z][a-z]* [A-Z][a-z]*",
        "email": r"[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}",
        "phone": r"\d{3}-\d{3}-\d{4}",
        "ssn": r"\d{3}-\d{2}-\d{4}",
    }

    # Mask detected PII
    masked_data = data.copy()
    for key, pattern in patterns.items():
        if key in data:
            masked_data[key] = re.sub(pattern, "[MASKED]", data[key])

    return masked_data
`,
	maskIp: `def handler(data, log):
    ip = data.get('ip_address', '')
    masked_ip = '.'.join(ip.split('.')[:-1] + ['0'])
    data['ip_address'] = masked_ip

    return data
`,
}

export const CreatePipelinePageModel = types
	.model("CreatePipelinePage", {
		isCreating: types.optional(types.boolean, false),
		createdPipelineId: types.maybe(types.string),
		accessToken: types.maybe(AccessTokenModel),
		spaces: types.array(SpaceModel),
		setupForm: types.optional(
			types
				.model("SetupForm", {
					name: types.optional(
						types.model("name", {
							value: types.optional(types.string, ""),
							hasError: types.optional(types.boolean, false),
							errorMessage: types.optional(types.string, ""),
						}),
						{},
					),
					space: types.optional(
						types.model("space", {
							value: types.optional(types.string, ""),
						}),
						{},
					),
				})
				.actions((self) => {
					return {
						reset() {
							self.name.value = ""
							self.name.hasError = false
						},
						validate() {
							if (
								self.name.value === "" ||
								self.name.value === undefined
							) {
								self.name.hasError = true
								self.name.errorMessage =
									"Your pipeline needs a name"
							} else {
								self.name.hasError = false
							}
						},
						setName(name: string) {
							self.name.value = name
							self.name.hasError = false
						},
						setSpace(spaceId: string) {
							self.space.value = spaceId
						},
					}
				})
				.views((self) => {
					return {
						get hasError() {
							return self.name.hasError
						},
					}
				}),
			{},
		),
		sourceForm: types.optional(
			types
				.model("SourceForm", {
					connector: types.optional(
						types.union(
							types.model("SDK", {
								type: types.literal("sdk"),
								meta: types.optional(types.model(), {}),
							}),
							types.model("Webhook", {
								type: types.literal("webhook"),
								meta: types.optional(types.model(), {}),
							}),
							types.model("PubSub", {
								type: types.literal("google_pubsub"),
								meta: types.optional(
									types
										.model({
											projectId: types.optional(
												types.model("ProjectId", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											subscriptionId: types.optional(
												types.model("SubscriptionId", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											credentials: types.optional(
												types.model("Credentials", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
										})
										.actions((self) => {
											return {
												setProjectId(
													projectId: string,
												) {
													self.projectId.value =
														projectId

													if (projectId !== "") {
														self.projectId.hasError = false
													}
												},
												setSubscriptionId(
													subscriptionId: string,
												) {
													self.subscriptionId.value =
														subscriptionId

													if (subscriptionId !== "") {
														self.subscriptionId.hasError = false
													}
												},
												setCredentials(
													credentials: string,
												) {
													self.credentials.value =
														credentials

													if (credentials !== "") {
														self.credentials.hasError = false
													}
												},
											}
										}),
									{},
								),
							}),
							types.model("SQS", {
								type: types.literal("amazon_sqs"),
								meta: types.optional(
									types
										.model({
											queueUrl: types.optional(
												types.model("QueueURL", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											region: types.optional(
												types.model("Region", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											accessKey: types.optional(
												types.model("AccessKey", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											secretKey: types.optional(
												types.model("SecretKey", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
										})
										.actions((self) => {
											return {
												setQueueUrl(queueUrl: string) {
													self.queueUrl.value =
														queueUrl

													if (queueUrl !== "") {
														self.queueUrl.hasError = false
													}
												},
												setRegion(region: string) {
													self.region.value = region

													if (region !== "") {
														self.region.hasError = false
													}
												},
												setAccessKey(
													accessKey: string,
												) {
													self.accessKey.value =
														accessKey

													if (accessKey !== "") {
														self.accessKey.hasError = false
													}
												},
												setSecretKey(
													secretKey: string,
												) {
													self.secretKey.value =
														secretKey

													if (secretKey !== "") {
														self.secretKey.hasError = false
													}
												},
											}
										}),
									{},
								),
							}),
							types.model("Postgres", {
								type: types.literal("postgres"),
								meta: types.optional(
									types
										.model({
											host: types.optional(
												types.model("Host", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											port: types.optional(
												types.model("Port", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											username: types.optional(
												types.model("Username", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											password: types.optional(
												types.model("Password", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											database_name: types.optional(
												types.model("DatabaseName", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											replication_slot: types.optional(
												types.model("ReplicationSlot", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											replication_output_plugin_args:
												types.optional(
													types.array(types.string), // Just an array of strings
													[], // Default value is an empty array
												),
										})
										.actions((self) => {
											return {
												setHost(host: string) {
													self.host.value = host
													if (host !== "") {
														self.host.hasError = false
													}
												},
												setPort(port: string) {
													self.port.value = port

													if (port !== "") {
														self.port.hasError = false
													}
												},
												setUsername(username: string) {
													self.username.value =
														username

													if (username !== "") {
														self.username.hasError = false
													}
												},
												setPassword(password: string) {
													self.password.value =
														password

													if (password !== "") {
														self.password.hasError = false
													}
												},
												setDatabaseName(
													databaseName: string,
												) {
													self.database_name.value =
														databaseName

													if (databaseName !== "") {
														self.database_name.hasError = false
													}
												},
												setReplicationSlot(
													replicationSlot: string,
												) {
													self.replication_slot.value =
														replicationSlot

													if (
														replicationSlot !== ""
													) {
														self.replication_slot.hasError = false
													}
												},
												setReplicationOutputPluginSlot(
													replicationOutputPluginSlot: string,
												) {
													self.replication_output_plugin_args.replace(
														[
															replicationOutputPluginSlot,
														],
													)
												},
											}
										}),
									{},
								),
							}),
						),
						{
							type: "sdk",
							meta: {},
						},
					),
				})
				.actions((self) => {
					return {
						reset() {
							self.connector = cast({ type: "sdk", meta: {} })
						},
						setType(
							type:
								| "sdk"
								| "webhook"
								| "amazon_sqs"
								| "google_pubsub"
								| "postgres",
						) {
							// biome-ignore lint/suspicious/noExplicitAny: Required here
							self.connector = { type, meta: {} } as any
						},

						setPubsubConnector({
							project_id,
							subscription_id,
							credentials_json,
						}: {
							project_id: string
							subscription_id: string
							credentials_json: string
						}) {
							self.connector.meta = {
								projectId: {
									value: project_id,
									hasError: false,
									errorMessage: "",
								},
								subscriptionId: {
									value: subscription_id,
									hasError: false,
									errorMessage: "",
								},
								credentials: {
									value: credentials_json,
									hasError: false,
									errorMessage: "",
								},
							}
						},

						setPostgresConnector({
							db_host,
							db_port,
							db_user,
							db_pass,
							db_name,
							replication_slot,
							// replication_output_plugin_args,
						}: {
							db_host: string
							db_port: string
							db_user: string
							db_pass: string
							db_name: string
							replication_slot: string
							// // biome-ignore lint/suspicious/noExplicitAny: The type provided from Novu is incorrect
							// replication_output_plugin_args: any
						}) {
							self.connector.meta = {
								host: {
									value: db_host,
									hasError: false,
									errorMessage: "",
								},
								port: {
									value: db_port,
									hasError: false,
									errorMessage: "",
								},
								username: {
									value: db_user,
									hasError: false,
									errorMessage: "",
								},
								password: {
									value: db_pass,
									hasError: false,
									errorMessage: "",
								},
								database_name: {
									value: db_name,
									hasError: false,
									errorMessage: "",
								},
								replication_slot: {
									value: replication_slot,
									hasError: false,
									errorMessage: "",
								},
								// replication_output_plugin_args:
								// 	replication_output_plugin_args.toJSON(),
							}
						},

						setSQSConnector({
							queue_url,
							aws_region,
							aws_access_key,
							aws_secret_key,
						}: {
							queue_url: string
							aws_region: string
							aws_access_key: string
							aws_secret_key: string
						}) {
							self.connector.meta = {
								queueUrl: {
									value: queue_url,
									hasError: false,
									errorMessage: "",
								},
								region: {
									value: aws_region,
									hasError: false,
									errorMessage: "",
								},
								accessKey: {
									value: aws_access_key,
									hasError: false,
									errorMessage: "",
								},
								secretKey: {
									value: aws_secret_key,
									hasError: false,
									errorMessage: "",
								},
							}
						},

						validate() {
							if (self.connector.type === "google_pubsub") {
								for (let field of [
									"projectId",
									"subscriptionId",
									"credentials",
								] as const) {
									if (
										self.connector.meta[field].value !== ""
									) {
										self.connector.meta[
											field
										].errorMessage = ""
										self.connector.meta[field].hasError =
											false
									} else {
										self.connector.meta[field].hasError =
											true
										self.connector.meta[
											field
										].errorMessage =
											"This field is required."
									}
								}
							} else if (self.connector.type === "amazon_sqs") {
								for (let field of [
									"queueUrl",
									"region",
									"accessKey",
									"secretKey",
								] as const) {
									if (
										self.connector.meta[field].value !== ""
									) {
										self.connector.meta[
											field
										].errorMessage = ""
										self.connector.meta[field].hasError =
											false
									} else {
										self.connector.meta[field].hasError =
											true
										self.connector.meta[
											field
										].errorMessage =
											"This field is required."
									}
								}
							} else if (self.connector.type === "postgres") {
								for (let field of [
									"host",
									"port",
									"username",
									"password",
									"database_name",
									"replication_slot",
								] as const) {
									if (
										self.connector.meta[field].value !== ""
									) {
										self.connector.meta[
											field
										].errorMessage = ""
										self.connector.meta[field].hasError =
											false
									} else {
										self.connector.meta[field].hasError =
											true
										self.connector.meta[
											field
										].errorMessage =
											"This field is required."
									}
								}
							}
						},
					}
				})
				.views((self) => {
					return {
						get hasError() {
							if (self.connector.type === "google_pubsub") {
								for (let field of [
									"projectId",
									"subscriptionId",
									"credentials",
								] as const) {
									if (self.connector.meta[field].hasError) {
										return true
									}
								}

								return false
							} else if (self.connector.type === "amazon_sqs") {
								for (let field of [
									"queueUrl",
									"region",
									"accessKey",
									"secretKey",
								] as const) {
									if (self.connector.meta[field].hasError) {
										return true
									}
								}
								return false
							} else if (self.connector.type === "postgres") {
								for (let field of [
									"host",
									"port",
									"username",
									"password",
									"database_name",
									"replication_slot",
								] as const) {
									if (self.connector.meta[field].hasError) {
										return true
									}
								}
								return false
							} else {
								return false
							}
						},
					}
				}),
			{},
		),
		transformerForm: types.optional(
			types
				.model("TransformerForm", {
					requirements: types.optional(
						types.model("file", {
							value: types.optional(types.string, ""),
							hasError: types.optional(types.boolean, false),
							errorMessage: types.optional(types.string, ""),
						}),
						{},
					),
					handler: types.optional(
						types.model("file", {
							value: types.optional(types.string, ""),
							hasError: types.optional(types.boolean, false),
							errorMessage: types.optional(types.string, ""),
						}),
						{},
					),
					environmentVariables: types.array(
						types.model({
							name: types.string,
							value: types.string,
						}),
					),
				})
				.actions((self) => {
					return {
						reset() {
							self.requirements.value = ""
							self.requirements.hasError = false
							self.handler.value = templates.echo
							self.handler.hasError = false
						},
						validate() {
							if (
								self.handler.value === "" ||
								self.handler.value === undefined
							) {
								self.handler.hasError = true
								self.handler.errorMessage =
									"This field is required"
							} else {
								// Parse the Editor contents for a top level definition
								// of a function with the name `handler`
								let hasHandlerFunction = false

								const lines = self.handler.value.split("\n")

								for (let line of lines) {
									if (line.startsWith("def")) {
										if (
											line
												.slice(3)
												.trim()
												.startsWith("handler") &&
											line
												.slice(3)
												.trim()
												.slice(7)
												.trim()
												.startsWith("(")
										) {
											hasHandlerFunction = true
											break
										}
									}
								}

								if (!hasHandlerFunction) {
									self.handler.hasError = true
									self.handler.errorMessage =
										"You need to define the `handler` function."
								}
							}
						},
						setHandler(handler: string) {
							self.handler.value = handler
							self.handler.hasError = false
						},
						setRequirements(requirements: string) {
							self.requirements.value = requirements
							self.requirements.hasError = false
						},
						selectTemplate(template: string) {
							if (template === "echo") {
								self.handler.value = templates.echo
								self.handler.hasError = false
							} else if (template === "maskIp") {
								self.handler.value = templates.maskIp
								self.handler.hasError = false
							} else if (template === "pii") {
								self.handler.value = templates.pii
								self.handler.hasError = false
							} else if (template === "removeNull") {
								self.handler.value = templates.removeNull
								self.handler.hasError = false
							} else if (template === "aiAnomalyDetection") {
								self.handler.value =
									templates.aiAnomalyDetection
								self.handler.hasError = false
							}
						},
						setEnvironmentVariables(
							environmentVariables: Array<{
								name: string
								value: string
							}>,
						) {
							self.environmentVariables =
								cast(environmentVariables)
						},
					}
				})
				.views((self) => {
					return {
						get hasError() {
							return self.handler.hasError
						},
						get selectedTemplate() {
							if (self.handler.value === templates.echo) {
								return "echo"
							} else if (
								self.handler.value === templates.maskIp
							) {
								return "maskIp"
							} else if (self.handler.value === templates.pii) {
								return "pii"
							} else if (
								self.handler.value === templates.removeNull
							) {
								return "removeNull"
							} else if (
								self.handler.value ===
								templates.aiAnomalyDetection
							) {
								return "aiAnomalyDetection"
							} else {
								return ""
							}
						},
					}
				}),
			{},
		),
		sinkForm: types.optional(
			types
				.model("SinkForm", {
					connector: types.optional(
						types.union(
							types.model("SDK", {
								type: types.literal("sdk"),
								meta: types.optional(types.model(), {}),
							}),
							types.model("Webhook", {
								type: types.literal("webhook"),
								meta: types.optional(
									types
										.model({
											url: types.optional(
												types.model("URL", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											method: types.optional(
												types.union(
													types.literal("POST"),
													types.literal("GET"),
													types.literal("PUT"),
													types.literal("PATCH"),
												),
												"POST",
											),
											headers: types.optional(
												types.array(
													types.model("Header", {
														name: types.string,
														value: types.string,
													}),
												),
												[{ name: "", value: "" }],
											),
										})
										.actions((self) => {
											return {
												setUrl(url: string) {
													self.url.value = url

													if (url !== "") {
														self.url.hasError = false
													}
												},
												setMethod(
													method:
														| "POST"
														| "GET"
														| "PUT"
														| "PATCH",
												) {
													self.method = method
												},
												addHeader() {
													self.headers = cast([
														...self.headers,
														{ name: "", value: "" },
													])
												},
												removeHeader(index: number) {
													if (
														self.headers.length > 1
													) {
														let clone =
															self.headers.slice(
																0,
															)
														clone.splice(index, 1)
														self.headers =
															cast(clone)
													} else {
														self.headers = cast([
															{
																name: "",
																value: "",
															},
														])
													}
												},
												setHeaderKey(
													index: number,
													key: string,
												) {
													let clone =
														self.headers.slice(0)
													clone[index].name = key
													self.headers = cast(clone)
												},
												setHeaderValue(
													index: number,
													value: string,
												) {
													let clone =
														self.headers.slice(0)
													clone[index].value = value
													self.headers = cast(clone)
												},
											}
										}),
									{},
								),
							}),
							types.model("Clickhouse", {
								type: types.literal("clickhouse"),
								meta: types.optional(
									types
										.model({
											addr: types.optional(
												types.model("Address", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											database: types.optional(
												types.model("Database", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											username: types.optional(
												types.model("Username", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											password: types.optional(
												types.model("Password", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											table: types.optional(
												types.model("Table", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
										})
										.actions((self) => {
											return {
												setAddress(address: string) {
													self.addr.value = address

													if (address !== "") {
														self.addr.hasError = false
													}
												},
												setDatabase(database: string) {
													self.database.value =
														database

													if (database !== "") {
														self.database.hasError = false
													}
												},
												setUsername(username: string) {
													self.username.value =
														username

													if (username !== "") {
														self.username.hasError = false
													}
												},
												setPassword(password: string) {
													self.password.value =
														password

													if (password !== "") {
														self.password.hasError = false
													}
												},
												setTable(table: string) {
													self.table.value = table

													if (table !== "") {
														self.table.hasError = false
													}
												},
											}
										}),
									{},
								),
							}),
							types.model("Amazon S3", {
								type: types.literal("amazon_s3"),
								meta: types.optional(
									types
										.model({
											s3_bucket: types.optional(
												types.model("BucketName", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											s3_key: types.optional(
												types.model("FolderName", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											region: types.optional(
												types.model("Region", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											accessKey: types.optional(
												types.model("AccessKey", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											secretKey: types.optional(
												types.model("SecretKey", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
										})
										.actions((self) => {
											return {
												setBucket(bucket: string) {
													self.s3_bucket.value =
														bucket

													if (bucket !== "") {
														self.s3_bucket.hasError = false
													}
												},
												setKey(key: string) {
													self.s3_key.value = key

													if (key !== "") {
														self.s3_key.hasError = false
													}
												},
												setRegion(region: string) {
													self.region.value = region

													if (region !== "") {
														self.region.hasError = false
													}
												},
												setAccessKey(
													accessKey: string,
												) {
													self.accessKey.value =
														accessKey

													if (accessKey !== "") {
														self.accessKey.hasError = false
													}
												},
												setSecretKey(
													secretKey: string,
												) {
													self.secretKey.value =
														secretKey

													if (secretKey !== "") {
														self.secretKey.hasError = false
													}
												},
											}
										}),
									{},
								),
							}),
							types.model("Snowflake", {
								type: types.literal("snowflake_cdc_json"),
								meta: types.optional(
									types
										.model({
											account: types.optional(
												types.model("Account", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											warehouse: types.optional(
												types.model("Warehouse", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											db_user: types.optional(
												types.model("DBUser", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											db_pass: types.optional(
												types.model("DBPass", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											db_name: types.optional(
												types.model("DBName", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											db_schema: types.optional(
												types.model("DBSchema", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											// db_host: types.optional(
											// 	types.model("DBHost", {
											// 		value: types.optional(
											// 			types.string,
											// 			"",
											// 		),
											// 		hasError: types.optional(
											// 			types.boolean,
											// 			false,
											// 		),
											// 		errorMessage:
											// 			types.optional(
											// 				types.string,
											// 				"",
											// 			),
											// 	}),
											// 	{},
											// ),
											// db_port: types.optional(
											// 	types.model("DBPort", {
											// 		value: types.optional(
											// 			types.string,
											// 			"",
											// 		),
											// 		hasError: types.optional(
											// 			types.boolean,
											// 			false,
											// 		),
											// 		errorMessage:
											// 			types.optional(
											// 				types.string,
											// 				"",
											// 			),
											// 	}),
											// 	{},
											// ),
											db_role: types.optional(
												types.string,
												"",
											),
										})
										.actions((self) => {
											return {
												setAccount(account: string) {
													self.account.value = account

													if (account !== "") {
														self.account.hasError = false
													}
												},
												setWarehouse(
													warehouse: string,
												) {
													self.warehouse.value =
														warehouse

													if (warehouse !== "") {
														self.warehouse.hasError = false
													}
												},
												setDBUser(user: string) {
													self.db_user.value = user

													if (user !== "") {
														self.db_user.hasError = false
													}
												},
												setDBPass(pass: string) {
													self.db_pass.value = pass

													if (pass !== "") {
														self.db_pass.hasError = false
													}
												},
												setDBName(name: string) {
													self.db_name.value = name

													if (name !== "") {
														self.db_name.hasError = false
													}
												},
												setDBSchema(schema: string) {
													self.db_schema.value =
														schema

													if (schema !== "") {
														self.db_schema.hasError = false
													}
												},
												// setDBHost(host: string) {
												// 	self.db_host.value = host

												// 	if (host !== "") {
												// 		self.db_host.hasError = false
												// 	}
												// },
												// setDBPort(port: string) {
												// 	self.db_port.value = port

												// 	if (port !== "") {
												// 		self.db_port.hasError = false
												// 	}
												// },
												setDBRole(role: string) {
													self.db_role = role
												},
											}
										}),
									{},
								),
							}),
							types.model("Pinecone", {
								type: types.literal("pinecone_json"),
								meta: types.optional(
									types
										.model({
											api_key: types.optional(
												types.model("APIKey", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											api_host: types.optional(
												types.model("APIHost", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											index_host: types.optional(
												types.model("IndexHost", {
													value: types.optional(
														types.string,
														"",
													),
													hasError: types.optional(
														types.boolean,
														false,
													),
													errorMessage:
														types.optional(
															types.string,
															"",
														),
												}),
												{},
											),
											api_source_tag: types.optional(
												types.string,
												"",
											),
											// client_headers: types.optional(
											// 	types.array(types.model({
											// 		name: types.string,
											// 		value: types.string,
											// 	})),
											// 	[],
											// ),
										})
										.actions((self) => {
											return {
												setAPIKey(str: string) {
													self.api_key.value = str

													if (str !== "") {
														self.api_key.hasError = false
													}
												},
												setAPIHost(str: string) {
													self.api_host.value = str

													if (str !== "") {
														self.api_host.hasError = false
													}
												},
												setIndexHost(str: string) {
													self.index_host.value = str

													if (str !== "") {
														self.index_host.hasError = false
													}
												},
												setAPISourceTag(str: string) {
													self.api_source_tag = str
												},
												// setClientHeaders(str: string) {
												// 	self.client_headers = str
												// },
											}
										}),
									{},
								),
							}),
						),
						{
							type: "sdk",
							meta: {},
						},
					),
				})
				.actions((self) => {
					return {
						reset() {
							self.connector = cast({ type: "sdk", meta: {} })
						},
						setType(
							type:
								| "sdk"
								| "webhook"
								| "clickhouse"
								| "amazon_s3"
								| "snowflake_cdc_json"
								| "pinecone_json",
						) {
							// biome-ignore lint/suspicious/noExplicitAny: Required here
							self.connector = { type, meta: {} } as any
						},

						setClickhouseConnector({
							addr,
							database,
							username,
							password,
							table,
						}: {
							addr: string
							database: string
							username: string
							password: string
							table: string
						}) {
							self.connector.meta = {
								addr: {
									value: addr,
									hasError: false,
									errorMessage: "",
								},
								database: {
									value: database,
									hasError: false,
									errorMessage: "",
								},
								username: {
									value: username,
									hasError: false,
									errorMessage: "",
								},
								password: {
									value: password,
									hasError: false,
									errorMessage: "",
								},
								table: {
									value: table,
									hasError: false,
									errorMessage: "",
								},
							} as typeof self.connector.meta
						},

						setAmazonS3Connector({
							s3_bucket,
							s3_key,
							aws_region,
							aws_access_key,
							aws_secret_key,
						}: {
							s3_bucket: string
							s3_key: string
							aws_region: string
							aws_access_key: string
							aws_secret_key: string
						}) {
							self.connector.meta = {
								s3_bucket: {
									value: s3_bucket,
									hasError: false,
									errorMessage: "",
								},
								s3_key: {
									value: s3_key,
									hasError: false,
									errorMessage: "",
								},
								region: {
									value: aws_region,
									hasError: false,
									errorMessage: "",
								},
								accessKey: {
									value: aws_access_key,
									hasError: false,
									errorMessage: "",
								},
								secretKey: {
									value: aws_secret_key,
									hasError: false,
									errorMessage: "",
								},
							}
						},

						setSnowflakeConnector({
							account,
							warehouse,
							db_user,
							db_pass,
							db_name,
							db_schema,
							// db_host,
							// db_port,
							db_role,
						}: {
							account: string
							warehouse: string
							db_user: string
							db_pass: string
							db_name: string
							db_schema: string
							db_host: string
							db_port: string
							db_role: string
						}) {
							self.connector.meta = {
								account: {
									value: account,
									hasError: false,
									errorMessage: "",
								},
								warehouse: {
									value: warehouse,
									hasError: false,
									errorMessage: "",
								},
								db_user: {
									value: db_user,
									hasError: false,
									errorMessage: "",
								},
								db_pass: {
									value: db_pass,
									hasError: false,
									errorMessage: "",
								},
								db_name: {
									value: db_name,
									hasError: false,
									errorMessage: "",
								},
								db_schema: {
									value: db_schema,
									hasError: false,
									errorMessage: "",
								},
								// db_host: {
								// 	value: db_host,
								// 	hasError: false,
								// 	errorMessage: "",
								// },
								// db_port: {
								// 	value: db_port,
								// 	hasError: false,
								// 	errorMessage: "",
								// },
								db_role: db_role || "",
							}
						},

						setPineconeConnector({
							api_key,
							api_host,
							index_host,
							api_source_tag,
							client_headers,
						}: {
							api_key: string
							api_host: string
							index_host: string
							api_source_tag: string
							client_headers: string
						}) {
							self.connector.meta = {
								api_key: {
									value: api_key,
									hasError: false,
									errorMessage: "",
								},
								api_host: {
									value: api_host,
									hasError: false,
									errorMessage: "",
								},
								index_host: {
									value: index_host,
									hasError: false,
									errorMessage: "",
								},
								api_source_tag: api_source_tag || "",
								// client_headers: client_headers || "",
							}
						},

						setWebhookConnector({
							url,
							method,
							headers,
						}: {
							url: string
							method: "GET" | "POST" | "PUT" | "PATCH"
							headers: Record<string, string>
						}) {
							const headersArray = Object.entries(headers).map(
								([name, value]) => ({
									name: name,
									value: value,
								}),
							)
							self.connector.meta = {
								url: {
									value: url,
									hasError: false,
									errorMessage: "",
								},
								method: method,
								headers: cast(headersArray),
							}
						},

						validate() {
							if (self.connector.type === "webhook") {
								if (self.connector.meta.url.value !== "") {
									self.connector.meta.url.errorMessage = ""
									self.connector.meta.url.hasError = false
								} else {
									self.connector.meta.url.hasError = true
									self.connector.meta.url.errorMessage =
										"This field is required."
								}
							} else if (self.connector.type === "clickhouse") {
								for (let field of [
									"addr",
									"database",
									"table",
									"username",
									"password",
								] as const) {
									if (
										self.connector.meta[field].value !== ""
									) {
										self.connector.meta[
											field
										].errorMessage = ""
										self.connector.meta[field].hasError =
											false
									} else {
										self.connector.meta[field].hasError =
											true
										self.connector.meta[
											field
										].errorMessage =
											"This field is required."
									}
								}
							} else if (self.connector.type === "amazon_s3") {
								for (let field of [
									"s3_bucket",
									"s3_key",
									"region",
									"accessKey",
									"secretKey",
								] as const) {
									if (
										self.connector.meta[field].value !== ""
									) {
										self.connector.meta[
											field
										].errorMessage = ""
										self.connector.meta[field].hasError =
											false
									} else {
										self.connector.meta[field].hasError =
											true
										self.connector.meta[
											field
										].errorMessage =
											"This field is required."
									}
								}
							} else if (
								self.connector.type === "snowflake_cdc_json"
							) {
								for (let field of [
									"account",
									"warehouse",
									"db_user",
									"db_pass",
									"db_name",
									"db_schema",
									// "db_host",
									// "db_port",
								] as const) {
									if (
										self.connector.meta[field].value !== ""
									) {
										self.connector.meta[
											field
										].errorMessage = ""
										self.connector.meta[field].hasError =
											false
									} else {
										self.connector.meta[field].hasError =
											true
										self.connector.meta[
											field
										].errorMessage =
											"This field is required."
									}
								}
							} else if (
								self.connector.type === "pinecone_json"
							) {
								for (let field of [
									"api_key",
									"api_host",
									"index_host",
								] as const) {
									if (
										self.connector.meta[field].value !== ""
									) {
										self.connector.meta[
											field
										].errorMessage = ""
										self.connector.meta[field].hasError =
											false
									} else {
										self.connector.meta[field].hasError =
											true
										self.connector.meta[
											field
										].errorMessage =
											"This field is required."
									}
								}
							}
						},
					}
				})
				.views((self) => {
					return {
						get hasError() {
							if (self.connector.type === "webhook") {
								if (self.connector.meta.url.hasError) {
									return true
								} else {
									return false
								}
							} else if (self.connector.type === "clickhouse") {
								for (let field of [
									"addr",
									"database",
									"table",
									"username",
									"password",
								] as const) {
									if (self.connector.meta[field].hasError) {
										return true
									}
								}

								return false
							} else if (self.connector.type === "amazon_s3") {
								for (let field of [
									"s3_bucket",
									"s3_key",
									"region",
									"accessKey",
									"secretKey",
								] as const) {
									if (self.connector.meta[field].hasError) {
										return true
									}
								}

								return false
							} else if (
								self.connector.type === "snowflake_cdc_json"
							) {
								for (let field of [
									"account",
									"warehouse",
									"db_user",
									"db_pass",
									"db_name",
									"db_schema",
									// "db_host",
									// "db_port",
								] as const) {
									if (self.connector.meta[field].hasError) {
										return true
									}
								}

								return false
							} else if (
								self.connector.type === "pinecone_json"
							) {
								for (let field of [
									"api_key",
									"api_host",
									"index_host",
								] as const) {
									if (self.connector.meta[field].hasError) {
										return true
									}
								}

								return false
							} else {
								return false
							}
						},
					}
				}),
			{},
		),
		editorSettings: types.optional(
			types
				.model({
					showInvisibles: types.optional(types.boolean, false),
					softWrap: types.optional(types.boolean, true),
				})
				.actions((self) => {
					return {
						setShowInvisibles(value: boolean) {
							self.showInvisibles = value
						},

						setSoftWrap(value: boolean) {
							self.softWrap = value
						},
					}
				}),
			{},
		),
		error: types.maybe(types.frozen()),
		isFetchingSpaces: types.optional(types.boolean, false),
	})
	.actions((self) => {
		const resetPage = () => {
			self.createdPipelineId = undefined
			self.isCreating = false
			self.accessToken = undefined
			self.setupForm.reset()
			self.sourceForm.reset()
			self.sinkForm.reset()
			self.transformerForm.reset()
			self.editorSettings.setShowInvisibles(false)
			self.editorSettings.setSoftWrap(true)
			self.error = undefined

			Fathom.trackEvent("Pipeline:Create:Started")
		}

		const getSpaces = flow(function* () {
			self.isFetchingSpaces = true

			self.spaces = cast([
				{
					id: "",
					name: "Loading Spaces...",
					created_at: "",
					permission: "",
				},
			])
			self.setupForm.space.value = self.spaces[0].id

			const spaces: Awaited<ReturnType<typeof api.listSpaces>> =
				yield api.listSpaces({
					queries: { page_size: 100 },
				})

			self.spaces = cast(spaces.spaces)
			self.setupForm.space.value = self.spaces[0].id

			self.isFetchingSpaces = false
		})

		const isTransformerFormValid = () => {
			self.transformerForm.validate()

			return !self.transformerForm.hasError
		}

		const isSourceFormValid = () => {
			self.sourceForm.validate()
			return !self.sourceForm.hasError
		}

		const isSinkFormValid = () => {
			self.sinkForm.validate()

			return !self.sinkForm.hasError
		}

		const createInitialPipeline = flow(function* () {
			let pipeline: Awaited<ReturnType<typeof createPipelineInitial>> =
				yield createPipelineInitial()

			if (pipeline !== false) {
				router.push("PipelinesDetailDetails", {
					pipelineId: pipeline.id,
				})
			}
		})

		const submit = flow(function* () {
			self.sourceForm.validate()
			self.transformerForm.validate()
			self.sinkForm.validate()
			self.setupForm.validate()

			if (
				!self.transformerForm.hasError &&
				!self.sourceForm.hasError &&
				!self.sinkForm.hasError &&
				!self.setupForm.hasError
			) {
				if (appStore.auth.isAuthenticated && isProduction()) {
					metricsApi.postMetricEvents(
						[
							{
								name: "pipeline_creation_submitted",
								created_at: new Date().toISOString(),
								data: {
									user_id: appStore.auth.profile.id,
									email: appStore.auth.profile.email,
									timestamp: new Date().toISOString(),
								},
								metadata: {
									source: appId,
								},
							},
						],
						{},
					)
				}
				let pipeline: Awaited<ReturnType<typeof createPipeline>> =
					yield createPipeline()

				if (pipeline !== false) {
					router.push("PipelinesDetailDetails", {
						pipelineId: pipeline.id,
					})
				}
			}
		})

		const createPipelineInitial = flow<
			Promise<false | Instance<typeof PipelineModel>>,
			[]
		>(function* () {
			try {
				self.isCreating = true

				let pipeline = yield api.createPipeline({
					name: self.setupForm.name.value,
					state: "paused",
					space_id: self.setupForm.space.value,
					transformation_function: templates.echo,
					metadata: {
						type: PipelineCreateType.Draft,
						step_transform: false,
						step_source: false,
						step_sink: false,
						steps_complete: false,
					},
				})

				self.createdPipelineId = pipeline.id

				let tokens: Awaited<ReturnType<typeof api.listAccessTokens>> =
					yield api.listAccessTokens({
						queries: {
							page_size: 1000,
						},
						params: {
							pipeline_id: pipeline.id,
						},
					})

				self.accessToken = tokens.access_tokens[0]

				Fathom.trackEvent("Pipeline:Create Success")
				self.isCreating = false

				return pipeline
			} catch (error) {
				self.isCreating = false
				self.error = error

				return false
			}
		})

		const createPipeline = flow<
			Promise<false | Instance<typeof PipelineModel>>,
			[]
		>(function* () {
			self.transformerForm.validate()
			self.sourceForm.validate()
			self.sinkForm.validate()
			self.setupForm.validate()

			if (
				self.setupForm.hasError ||
				self.sourceForm.hasError ||
				self.sinkForm.hasError ||
				self.transformerForm.hasError
			) {
				return false
			}

			try {
				self.isCreating = true

				if (self.sinkForm.connector.type === "webhook") {
					let x = self.sinkForm.connector.meta.headers.filter(
						({ name, value }) => {
							if (name === "" || value === "") {
								return false
							} else {
								return true
							}
						},
					)
				}

				let pipeline = yield api.createPipeline({
					name: self.setupForm.name.value,
					space_id: self.setupForm.space.value,
					transformation_function: self.transformerForm.handler.value,
					requirements_txt: self.transformerForm.requirements.value,
					metadata: {
						sourceConnector: {
							type: self.sourceForm.connector.type,
						},
						type: PipelineCreateType.Blank,
					},
					...(self.sourceForm.connector.type === "amazon_sqs"
						? {
								source_connector: {
									kind: "amazon_sqs",
									config: {
										queue_url:
											self.sourceForm.connector.meta
												.queueUrl.value,
										aws_region:
											self.sourceForm.connector.meta
												.region.value,
										aws_access_key:
											self.sourceForm.connector.meta
												.accessKey.value,
										aws_secret_key:
											self.sourceForm.connector.meta
												.secretKey.value,
									},
								},
							}
						: self.sourceForm.connector.type === "google_pubsub"
							? {
									source_connector: {
										kind: "google_pubsub",
										config: {
											project_id:
												self.sourceForm.connector.meta
													.projectId.value,
											subscription_id:
												self.sourceForm.connector.meta
													.subscriptionId.value,
											credentials_json:
												self.sourceForm.connector.meta
													.credentials.value,
										},
									},
								}
							: {}),
					...(self.sinkForm.connector.type === "webhook"
						? {
								sink_connector: {
									kind: "webhook",
									config: {
										url: self.sinkForm.connector.meta.url
											.value,
										method: self.sinkForm.connector.meta
											.method,
										headers: (<
											Array<{
												name: string
												value: string
											}>
										>self
											.sinkForm
											.connector.meta.headers).filter(
											({ name, value }) => {
												if (
													name === "" ||
													value === ""
												) {
													return false
												} else {
													return true
												}
											},
										),
									},
								},
							}
						: self.sinkForm.connector.type === "clickhouse"
							? {
									sink_connector: {
										kind: "clickhouse",
										config: {
											addr: self.sinkForm.connector.meta
												.addr.value,
											database:
												self.sinkForm.connector.meta
													.database.value,
											table: self.sinkForm.connector.meta
												.table.value,
											username:
												self.sinkForm.connector.meta
													.username.value,
											password:
												self.sinkForm.connector.meta
													.password.value,
										},
									},
								}
							: self.sinkForm.connector.type === "amazon_s3"
								? {
										sink_connector: {
											kind: "amazon_s3",
											config: {
												s3_bucket:
													self.sinkForm.connector.meta
														.s3_bucket.value,
												s3_key: self.sinkForm.connector
													.meta.s3_key.value,
												aws_region:
													self.sinkForm.connector.meta
														.region.value,
												aws_access_key:
													self.sinkForm.connector.meta
														.accessKey.value,
												aws_secret_key:
													self.sinkForm.connector.meta
														.secretKey.value,
											},
										},
									}
								: {}),
					environments: getSnapshot(
						self.transformerForm.environmentVariables,
					).filter(({ name, value }) => {
						if (name === "" || value === "") {
							return false
						} else {
							return true
						}
					}),
				})

				self.createdPipelineId = pipeline.id

				let tokens: Awaited<ReturnType<typeof api.listAccessTokens>> =
					yield api.listAccessTokens({
						queries: {
							page_size: 1000,
						},
						params: {
							pipeline_id: pipeline.id,
						},
					})

				self.accessToken = tokens.access_tokens[0]

				Fathom.trackEvent("Pipeline:Create Success")

				return pipeline
			} catch (error) {
				self.isCreating = false
				self.error = error

				return false
			}
		})

		return {
			submit,
			createInitialPipeline,
			isTransformerFormValid,
			isSourceFormValid,
			isSinkFormValid,
			resetPage,
			getSpaces,
			createPipeline,
		}
	})

export const pipelineCreatePageStore = CreatePipelinePageModel.create()
